.cls-1 {
    fill:#e6ecfb;
    }

.cls-2 {
    fill:#e7ecfb;
    }

.cls-3 {
    fill:#fbfcfe;
    }

.cls-4 {
    fill:#e5ebfb;
    }
.cls-5 {
    fill:#a4c2f6;
    }
.cls-6 {
    fill:#a4c1f6;
    }
.cls-7 {
    fill:#a3c1f6;
    }
.cls-8 {
    fill:#5eb1fa;
    }