/* Example styles for card element container */
.CardElement {
    font-size: 16px;
    padding: 10px;
    border: 1px solid #ccc;
    width: 100%;
    height: 40px;
}

.StripeElement {
    border: 1px solid #00aeef;
    border-radius: 7px;
    padding: 4%;

}

.__PrivateStripeElement {
    font-size: 30px;
}

#payment-form{
    width:100vw;
    height: auto;
    color:white;
}