.cta-header {
  /* position: absolute; */
  /* top: 40%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  color: #ffffff;
  font-size: 3.5rem;
}

.showcase {
  position: relative;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #111;
  transition: 0.5s;
  z-index: 900;
}

.showcase video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  opacity: 0.8;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  /* mix-blend-mode: overlay; */
  opacity: 50%;
  /* #03a9f4 */
}

.text {
  position: absolute;
  /* left:10%; */
  color:white;
}


.social {
  transform: scale(0.5);
  transition: 0.5s;
  filter: invert(1);
  height: 30px;
  color:white;
  width: 30px;
  flex-direction: row;
}

.social :hover {
  transform: scale(1.5) translateY(-15px);
}