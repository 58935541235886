.cls-1 {
    fill:#e7ecfb;
}
.cls-2 {
    fill:#fdfdfd;
}
.cls-3 {
    fill:#a4c1f6;
}
.cls-4 {
    fill:#5eb1fa;
}