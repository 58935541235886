.Layout-ios {
    padding-top: var(--iosTopPadding);
}

.Layout-android {
    padding-top: var(--androidTopPadding);
}

.App {
    position: relative;
    height: calc(100% - 70px);
    min-height: -webkit-fill-available;
    padding-bottom: 72px;
}