.css-sxw2zh.Mui-selected {
    color: #00aeef !important;
}

.MuiBottomNavigationAction-iconOnly {
    color: gray !important;
}

.BottomNav-ios {
    padding-bottom: 14px;
}