.mapboxgl-popup-content {
    /* background-color: black; */
    background-color: #121212;
    padding: 0;
}

.mapboxgl-popup-close-button {
    font-size: 36px;
    position: absolute;
    top: -20px;
    right: -15px;
    color: #FF6B6B;
}

.popupImg {
    object-fit: contain;
    width: 100%;
    height: 200px;
}

.opaque-true {
    opacity: 1;
}

.opaque-false {
    opacity: .2;
}

#mapComponentContainer .mapboxgl-map {
    position: fixed !important;
}
.mapComponentContainer-web .mapboxgl-map {
    height: calc(100% - 128px) !important;
}
.mapComponentContainer-ios .mapboxgl-map {
    height: calc(100% - 128px - var(--iosTopPadding)) !important;
}
.mapComponentContainer-android .mapboxgl-map {
    height: calc(100% - 128px - var(--androidTopPadding)) !important;
}
