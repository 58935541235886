@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap');

:root {
  --iosTopPadding: 52px;
  --androidTopPadding: 0px;
}

body {
  overflow-x: hidden;
  /* margin: 2rem; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Work Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

