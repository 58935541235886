.display-false {
  display: none;
}

/* button {
  background: transparent;
  font-family: 'Poppins';
  font-size: 10px;
  color: white;
  border-radius: 20%;
  height: 50px;
  width: 50px;
  border: 1px solid;
  border-color: #00aeef;
} */

.isSelected-true {
  background: #00aeef;
  color: white;
}

.addedTimeslotIndicator {
  position: absolute;
  top: 100%;
  left: 0;
  height: 5px;
  border-radius: 50%;
  width: 5px;
  border: 3px solid #00aeef;
}

.dayTimeslotContainer {
  display: flex;
  flex-direction: column;
}

.existingTimeslot {
  height: 48px;
  padding: 18px 12px;
  display: flex;
  align-items: center;
}

.toRemoveButton {
  background: pink;
  color:white;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border-width: 0;
  margin: 10px;
  cursor: pointer;
}

.toRemove {
  text-decoration: line-through;
}