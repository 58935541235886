.container {
    color: blue;
}

.grid {
    width: 100;
}
.grid-child {
    width: 100%;
    
}
.link {
    text-decoration: none;
}
.card {
    display: flex;
    flex-direction: row;
    max-width: 100vh; 
    color: rgb(55, 69, 228);    
    justify-content: space-between;

}

.cardLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    padding: 6px 0 6px 4px;
    margin: 2px;
}

/* .cardMedia {
    max-width: 100%;
    max-height: 400px;
} */

.cardContent {
    flex-direction: row;
    text-decoration: none;
}
.cardHeader {
    display: flex;
    flex-direction: row;
}

.cardFooter {
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 2px solid rgba(0, 0, 0, 0.331);
    border-radius: 5px;
}
